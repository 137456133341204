@tailwind base;
@tailwind components;
@tailwind utilities;

.react-app p {
  margin: 0px !important;
}

/* Featured Products Carousel */
.swiper-pagination-bullet {
  background-color: #d9d9d9 !important;
}

.swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  background-color: rgba(51, 51, 51, 0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 20px;
  width: auto !important;
  left: 50% !important;
  transform: translateX(-50%);
  bottom: 28px !important;
}

/* Branch Locator Map */
.map-results .map-image {
  width: 100%;
  display: block !important;
  margin-left: 0;
  margin-bottom: 0px !important;
  height: 355px !important;
}

#branchLocator {
  margin-bottom: 0px !important;
}

.branch-locator {
  margin: 14px 0px 0px 0px !important;
}

.branch-locator-marker-content {
  padding: 15px;
}

.branch-locator-marker-content img {
  max-width: 100px;
  margin-bottom: 10px;
}

.branch-locator-marker-content .branch-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.branch-locator-marker-content .branch-address,
.branch-locator-marker-content .branch-city-state-zipcode,
.branch-locator-marker-content .branch-phone {
  margin-bottom: 5px;
}

.branch-locator-marker-content .branch-specials,
.branch-locator-marker-content .branch-email {
  margin-top: 10px;
}

.branch-locator-marker-content a {
  color: #c60c30;
  text-decoration: none;
}

.branch-locator-marker-content a:hover {
  text-decoration: underline;
}

.single-action-input {
  border-radius: 1.5rem 0 0 1.5rem !important;
}

.single-action-btn {
  border-radius: 0 1.5rem 1.5rem 0 !important;
  border-left: none !important;
}

.branch-locator.ca .CESLocLogo {
  width: 75px !important;
  display: block;
}

.hmpage-map-markers.ca .gmaps-branchlist li {
  font-size: 16px;
  margin-bottom: 5px !important;
  font-weight: 300;
}

.hmpage-map-markers.ca {
  height: auto;
  padding: 10px 0;
}

@media (min-width: 768px) {
  .branch-locator {
    margin: 20px 0px 0px 0px !important;
  }
}

@layer components {
  /* Centered Container */
  .flex-center {
    @apply flex items-center justify-center;
  }

  /* Container Inner Shadow */
  .inner-shadow {
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.12);
  }

  /* Search Icon */
  .search-icon:hover path {
    fill: #b30426;
  }

  /* Search Input */
  .focus-shadow:focus-within {
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.4);
  }

  /* Hide scrollbar*/
  .hide-scrollbar::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Ellipsis 1 line*/
  .ellipsis-1-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Tooltip styles */
  .overlay-tooltip,
  .delivery-tooltip {
    @apply relative z-20 inline-block cursor-pointer;
  }

  .overlay-tooltip .overlay-tooltiptext {
    @apply absolute w-[255px] border border-text-primary bg-white p-[14px] text-[12px] text-text-primary opacity-0 shadow-md transition-opacity duration-300;
    visibility: hidden;
    bottom: 200%;
    left: 50%;
    transform: translateX(-50%);
  }

  .overlay-tooltip.sign-in.mobile .overlay-tooltiptext {
    transform: translateX(-90%);
  }

  .delivery-tooltip .delivery-tooltiptext {
    @apply absolute w-[197px] border border-text-primary bg-white p-[14px] text-[12px] text-text-primary opacity-0 shadow-md transition-opacity duration-300;
    visibility: hidden;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Position modifiers */
  .delivery-tooltip.top .delivery-tooltiptext {
    bottom: 150%;
  }

  .delivery-tooltip.bottom .delivery-tooltiptext {
    top: 150%;
  }

  .delivery-tooltip.bottom.mobile .delivery-tooltiptext {
    left: -45%;
  }

  .overlay-tooltip .overlay-tooltiptext::after,
  .overlay-tooltip .overlay-tooltiptext::before,
  .delivery-tooltip .delivery-tooltiptext::after,
  .delivery-tooltip .delivery-tooltiptext::before {
    content: "";
    @apply absolute;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
  }

  .delivery-tooltip.bottom.mobile .delivery-tooltiptext::after,
  .delivery-tooltip.bottom.mobile .delivery-tooltiptext::before {
    left: 79%;
  }

  .overlay-tooltip.sign-in.mobile .overlay-tooltiptext::after,
  .overlay-tooltip.sign-in.mobile .overlay-tooltiptext::before {
    left: 90%;
  }

  .overlay-tooltip .overlay-tooltiptext::before {
    bottom: -15px;
    border-width: 7px;
    border-color: black transparent transparent transparent;
  }

  .overlay-tooltip .overlay-tooltiptext::after {
    bottom: -16px;
    border-width: 9px;
    border-color: white transparent transparent transparent;
  }

  /* Top position arrows */
  .delivery-tooltip.top .delivery-tooltiptext::before {
    bottom: -15px;
    border-width: 7px;
    border-color: black transparent transparent transparent;
  }

  .delivery-tooltip.top .delivery-tooltiptext::after {
    bottom: -16px;
    border-width: 9px;
    border-color: white transparent transparent transparent;
  }

  /* Bottom position arrows */
  .delivery-tooltip.bottom .delivery-tooltiptext::before {
    top: -15px;
    border-width: 7px;
    border-color: transparent transparent black transparent;
  }

  .delivery-tooltip.bottom .delivery-tooltiptext::after {
    top: -16px;
    border-width: 9px;
    border-color: transparent transparent white transparent;
  }

  .overlay-tooltip:hover .overlay-tooltiptext,
  .delivery-tooltip .delivery-tooltiptext.visible {
    @apply opacity-100;
    visibility: visible;
  }

  .z-max {
    z-index: 99999;
  }
}
